import createModule, { QpdfInstance } from '@neslinesli93/qpdf-wasm';
import wasm from '@neslinesli93/qpdf-wasm/dist/qpdf.wasm?url';
import { AbortRequest, isAbortRequest, isUint8ArrayBuffer } from './communication';
import { uuidv4 } from 'lib0/random';

export interface QpdfWorkerBaseMessage {
  requestId: string;
  type: 'decrypt';
}

export interface DecryptMessage extends QpdfWorkerBaseMessage {
  type: 'decrypt';
  password?: string;
  pdf: ArrayBuffer;
}

let _qpdf: QpdfInstance | null = null;

async function runQpdfCmd(workingFile: ArrayBuffer, cmd: string[]) {
  if (_qpdf == null) {
    const _q = await createModule({
      locateFile: () => wasm
    });
    _q.FS.mkdir('/working');
    _q.FS.mkdir('/out');

    _qpdf = _q;
  }

  const qpdf = _qpdf;

  const outFileName = `/out/${uuidv4().replaceAll('-', '')}.pdf`;
  qpdf.FS.mount(
    qpdf.WORKERFS,
    { blobs: [{ name: 'input.pdf', data: new Blob([workingFile]) }] },
    '/working'
  );

  qpdf.callMain(['/working/input.pdf', ...cmd, '--', outFileName]);

  const outputFile = qpdf.FS.readFile(outFileName);
  qpdf.FS.unmount('/working');

  return outputFile;
}

async function decryptPdf(pdf: ArrayBuffer): Promise<Uint8Array> {
  return runQpdfCmd(pdf, ['--decrypt']);
}

self.onmessage = (e: MessageEvent<DecryptMessage | AbortRequest>) => {
  try {
    const data = e.data;

    if (isAbortRequest(data)) {
      return;
    }

    switch (data.type) {
      case 'decrypt': {
        decryptPdf(data.pdf).then(blob => {
          const buffer = isUint8ArrayBuffer(blob) ? blob.buffer : blob;
          self.postMessage({
            requestId: data.requestId,
            blob: buffer
          }, [buffer]);
        }).catch(e=>{
          self.postMessage({
            requestId: data.requestId,
            errCaught: {
              eName: e.constructor.name,
              eMsg: e.message
            },
            errInput: data.pdf
          }, [data.pdf]);

        });
        break;
      }

      default:
        console.error('Unknown worker message received');
    }

  } catch (e) {
    console.log(e);
  }
};

export {};
